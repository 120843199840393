import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'


import bwopenbooks from '../assets/images/bwopenbooks.jpg'
import matrix from '../assets/images/matrix.jpg'
import services from '../assets/images/services.jpg'
import phone from '../assets/images/phone.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Deriving Success"
                    meta={[
                        { name: 'description', content: 'Math tutoring and more' },
                        { name: 'keywords', content: 'tutoring, math, math help, tutor, zoom, online, online tutoring, cheap tutoring, high school, college, professional tutor, tutoring help' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${matrix})`}}>
                            <header className="major">
                                <h3>About Me</h3>
                                <p>Find out a bit about me</p>
                            </header>
                            <Link to="/aboutme" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${services})`}}>
                            <header className="major">
                                <h3>Services</h3>
                                <p>See what I can provide for you</p>
                            </header>
                            <Link to="/services" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${bwopenbooks})`}}>
                            <header className="major">
                                <h3>Credentials</h3>
                                <p>My background and experience</p>
                            </header>
                            <Link to="/credentials" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${phone})`}}>
                            <header className="major">
                                <h3>Contact</h3>
                                <p>Reach out or schedule a session</p>
                            </header>
                            <Link to="/contact" className="link primary"></Link>
                        </article>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
